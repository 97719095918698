<template>
  <el-dialog
    @close="close('ruleForm')"
    :visible.sync="dialogVisible"
    @open="open"
  >
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span> <span>{{ title }}</span>
      </div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <Divider title="基础功能"></Divider>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="功能名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属模块" prop="objectName">
            <el-select
              style="width: 100%"
              v-model="ruleForm.objectName"
              remote
              reserve-keyword
              placeholder="请选择所属模块名称"
              filterable
              @change="objectNameChange(ruleForm.objectName)"
            >
              <el-option
                v-for="item in typeList"
                :key="item.objectAlias"
                :label="item.objectAlias"
                :value="item.objectAlias"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="功能对应接口" prop="api">
            <el-select
              style="width: 100%"
              v-model="ruleForm.api"
              placeholder="请选择"
              @change="apiChange(ruleForm.api)"
            >
              <el-option
                v-for="(item, index) in apiList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="控制标识" prop="action">
            <el-input v-model="ruleForm.action"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="依赖权限" prop="desp">
        <el-select
          v-model="ruleForm.desp"
          multiple
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in relyList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="字段权限" prop="fields">
        <el-select
          v-model="ruleForm.fields"
          multiple
          placeholder="请选择"
          style="width: 100%"
          filterable
          allow-create
          @change="fieldsChange"
        >
          <el-option label="$all" value="$all"> </el-option>
          <el-option
            v-for="item in fieldsList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div style="display: flex; justify-content: space-between">
        <el-form-item label="是否禁用" prop="enable">
          <el-switch
            v-model="ruleForm.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否可配置" prop="custom">
          <el-switch
            v-model="ruleForm.custom"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否公共组件" prop="common">
          <el-switch
            v-model="ruleForm.common"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </div>
      <el-form-item label="描述" prop="desc">
        <el-input
          type="textarea"
          v-model="ruleForm.desc"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
      <Divider title="数据功能">
        <div slot="right">
          <el-button type="text" @click="addOne" style="padding: 0;">
            添加规则
          </el-button>
        </div>
      </Divider>
      <el-form-item label="数据权限" prop="scope">
        <ScopeVue
          @childByValue="childByValue"
          :inputName="fieldsList"
          :spoceList="ruleForm.scope"
          :list="list"
        ></ScopeVue>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close('ruleForm')">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as _ from 'lodash';
import { api } from '/src/api/base';
export const roleResourceNameCodes = api()('/roleResource.nameCodes.json');

export const roleResourceAdd = api()('/roleResource.add.json');
export const roleResourceEdit = api()('/roleResource.edit.json');
export const roleResourceBaseObjects = api()('/roleResource.baseObjects.json');
export const roleResourceOne = api()('/roleResource.one.json');
export default {
  props: {
    dialogVisible: Boolean,
    code: String,
    type: String
  },
  components: {
    ScopeVue: () => import('./scope'),
    Divider: () => import('/src/components/divider')
  },
  watch: {
    dialogVisible: function(val, old) {
      if (val === old) return;
      this.title =
        this.type === 'add'
          ? '新增资源'
          : this.type === 'edit'
          ? '编辑资源'
          : '复制资源';
      if (this.type === 'copy' || this.type === 'edit') {
        this.getRoleResourceOne();
      }
    }
  },
  data() {
    return {
      title: '新增资源',
      ruleForm: {
        scope: [],
        name: '',
        objectName: '',
        desc: '',
        desp: '',
        api: '',
        action: '',
        fields: '',
        enable: true,
        custom: true,
        common: true
      },
      rules: {
        name: [{ required: true, message: '请输入功能名称', trigger: 'blur' }],
        objectName: [
          { required: true, message: '请选择所属模块', trigger: 'change' }
        ],
        api: [
          { required: true, message: '请选择功能对应接口', trigger: 'change' }
        ],

        scope: [],
        desp: [],
        desc: [],
        type: [],
        action: [
          { required: true, message: '请输入控制标识', trigger: 'blur' }
        ],
        fields: [],
        enable: [],
        custom: [],
        common: [],
        fieldsList: []
      },
      typeList: [],
      apiList: [],
      relyList: [],
      fieldsList: [],
      loading: false,
      oldFields: [],
      list: [1]
    };
  },
  methods: {
    open() {
      this.getRoleResourceBaseObjects();
      this.getRoleResourceList();
    },
    async getRoleResourceList() {
      const res = await roleResourceNameCodes({
        pageSize: 1000
      });
      this.relyList = res;
    },
    getRoleResourceBaseObjects() {
      roleResourceBaseObjects().then(res => {
        this.typeList = res;
      });
    },
    objectNameChange(name) {
      const _selectOne = this.typeList.find(it => it.objectAlias === name);
      this.apiList = _selectOne.configApi;
      this.fieldsList = _selectOne.feilds;
    },
    apiChange(api) {
      this.ruleForm.action = _.camelCase(api.replace('.json', ''));
    },
    fieldsChange() {
      if (
        this.oldFields.indexOf('$all') === -1 &&
        this.ruleForm.fields.indexOf('$all') > -1
      ) {
        this.ruleForm.fields = ['$all'];
      }
      if (
        this.oldFields.indexOf('$all') > -1 &&
        this.ruleForm.fields.indexOf('$all') > -1 &&
        this.ruleForm.fields.length > 1
      ) {
        this.ruleForm.fields = this.ruleForm.fields.filter(it => it !== '$all');
      }
      this.oldFields = _.castArray(this.ruleForm.fields);
    },
    // 获取资源信息
    async getRoleResourceOne() {
      const result = await roleResourceOne({ code: this.code });
      result.fields = result.fields ? result.fields.split(',') : [];
      result.scope = JSON.parse(result.scope);
      this.apiList = this.typeList.filter(
        it => it.objectAlias === result.objectName
      )[0].configApi;
      this.fieldsList = this.typeList.filter(
        it => it.objectAlias === result.objectName
      )[0].feilds;
      Object.keys(this.ruleForm).forEach(key => {
        this.ruleForm[key] = result[key];
      });
    },
    childByValue(childByValue) {
      this.ruleForm.scope = childByValue;
    },
    close(form) {
      this.$refs[form].resetFields();
      this.list = [];
      this.ruleForm.scope = [];
      this.$emit('update:dialogVisible', false);
      this.$emit('close');
    },
    submitForm(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          const params = {
            ...this.ruleForm,
            scope: JSON.stringify(this.ruleForm.scope),
            fields: this.ruleForm.fields.join(',')
          };
          if (this.type === 'add' || this.type === 'copy') {
            await roleResourceAdd(params);
            this.$message.success(`资源 ${this.ruleForm.name} 添加成功！`);
          } else {
            await roleResourceEdit({
              code: this.code,
              ...params
            });
            this.$message.success(`资源 ${this.code} 编辑成功！`);
          }
          this.close(form);
        }
      });
    },
    addOne() {
      this.list.push(1);
    }
  }
};
</script>

<style></style>
